import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import PostList from "../components/post-list"

const BlogIndex = ({ data, location, pageContext }) => {
  const posts = data.allMdx.nodes
  const { currentPage, numPages, basePath } = pageContext
  const noindex = currentPage === 1 ? 0 : 1
  const title = currentPage === 1 ? "Home" : "Home(page" + currentPage + ")"

  return (
    <Layout location={location}>
      <Seo title={title} slug="/" noindex={noindex} />
      <PostList
        posts={posts}
        currentPage={currentPage}
        numPages={numPages}
        basePath={basePath}
      />
    </Layout>
  )
}
export default BlogIndex

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMdx(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date1], order: DESC }
      filter: { frontmatter: { static: { ne: true } } }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date1(locale: "ja")
          title
          tags
          topImage {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.9
                transformOptions: { cropFocus: CENTER }
                width: 500
              )
            }
          }
        }
      }
    }
  }
`
